import { useMemo } from 'react';
import { useMessages } from '../../../LocalisationProvider';
import { getLocalDateAndTime, getLocalisedMessage, round, useTimezone } from '../../../utils';
import { PlantData, PlantDataItemData } from '../../../utils/useHistoricalPlantData';
import { FeatureName } from '../../../types';
import { Column } from '../../../utils/useHistoricalQualityColumnData';
import { SoftSensor } from '../../../utils/useHistoricalSoftSensors';

type TableChartPayload = {
  id: number;
  [Column.Measured]: string | number;
  [Column.Predicted]: string | number;
  [Column.Date]?: string;
};

const getPredictedValue = (modelledData: SoftSensor[], timestamp: string, roundValues: boolean): undefined | number => {
  const value = modelledData.find((datum) => datum.timestamp.getTime() === new Date(timestamp).getTime())?.value;

  return value !== undefined && roundValues ? round(value, 0) : value;
};

const getMeasuredValue = (
  data: PlantDataItemData,
  featureName: FeatureName,
  roundValues: boolean
): undefined | number => {
  const value = data[featureName]?.value;
  return value !== undefined && roundValues ? round(value, 0) : value;
};

const useTableData = (
  modelledData: SoftSensor[],
  measuredData: PlantData,
  measured: FeatureName,
  roundValues: boolean
) => {
  const messages = useMessages();
  const naLabel = getLocalisedMessage(messages, 'N/A', 'labels.na');
  const timezone = useTimezone();
  const { items = [] } = measuredData;
  return useMemo(
    () =>
      items.map(({ timestamp, data }): TableChartPayload => {
        const predictedValue = getPredictedValue(modelledData, timestamp, roundValues);
        const measuredValue = getMeasuredValue(data, measured, roundValues);
        return {
          id: new Date(timestamp).getTime(),
          [Column.Predicted]: predictedValue ?? naLabel,
          [Column.Measured]: measuredValue ?? naLabel,
          [Column.Date]: timestamp ? getLocalDateAndTime(new Date(timestamp), timezone) : undefined
        };
      }),
    [modelledData, timezone, naLabel, items, measured, roundValues]
  );
};
export default useTableData;
