import { PlantData } from '../../../utils/useHistoricalPlantData';
import { SoftSensor } from '../../../utils/useHistoricalSoftSensors';
import { useHistoricalQualityColumnData } from '../../../utils';
import useTableData from './useTableData';
import { DataTable } from '../../../lib';
import { ChartConfig } from '../schema';

type Props = {
  modelledData: SoftSensor[];
  measuredData: PlantData;
  chartConfigs: ChartConfig[];
  fileName: string;
  roundValues: boolean;
};

const Table = ({ modelledData, measuredData, chartConfigs, fileName, roundValues }: Props) => {
  const measured = chartConfigs[1].featureName;
  const columnData = useHistoricalQualityColumnData(chartConfigs);
  const tableData = useTableData(modelledData, measuredData, measured, roundValues);

  return <DataTable data={tableData} columns={columnData} fileName={fileName} />;
};

export default Table;
