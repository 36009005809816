import { useSearchParams } from 'react-router-dom';
import { Label, Recommendation } from '../../../../types';
import useNow from '../../../../useNow';
import { useFulfillmentRecorder } from '../../../../utils';
import useCombinedQualityChartData from '../../../../utils/useCombinedQualityChartData';
import { PlantData } from '../../../../utils/useHistoricalPlantData';
import { SoftSensor } from '../../../../utils/useHistoricalSoftSensors';
import { QualityChartConfig } from '../../schema';
import QualityChart from '../QualityChart';
import HighlightTable from './HighlightTable/HighlightTable';

type Props = {
  modelledData: SoftSensor[];
  measuredData: PlantData;
  modelledLabel: Label;
  measuredLabel: Label;
  targetData: Recommendation[];
  oldTargetData: Recommendation[];
  startDate: Date;
  endDate: Date;
  config: QualityChartConfig;
  unit: string;
};

const FIFTEEN_MINUTES = 1000 * 60 * 15;
const ChartContainer = ({
  modelledData,
  measuredData,
  modelledLabel,
  measuredLabel,
  targetData,
  oldTargetData,
  startDate,
  endDate,
  config,
  unit
}: Props) => {
  const chartData = useCombinedQualityChartData(
    modelledData,
    measuredData,
    targetData,
    oldTargetData,
    config.modelled.name,
    config.measured.name,
    endDate,
    config.roundValues
  );
  const { setPredictionMetric } = useFulfillmentRecorder();
  const now = useNow();
  if (chartData.length > 0) {
    setPredictionMetric({
      feature: config.modelled.name,
      label: config.label.default,
      isFulfilled: modelledData.length > 0 && now.getTime() - chartData[0].date <= FIFTEEN_MINUTES,
      value: chartData[0]?.predicted ?? 0
    });
  }
  const [searchParams] = useSearchParams();
  const customer = searchParams.get('customer');
  return (
    <>
      {customer === 'adr' && (
        <HighlightTable data={chartData} modelledLabel={modelledLabel} measuredLabel={measuredLabel} unit={unit} />
      )}
      <QualityChart data={chartData} startDate={startDate} endDate={endDate} config={config} unit={unit} />
    </>
  );
};

export default ChartContainer;
