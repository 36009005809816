import { addHours, subHours } from 'date-fns';
import { TemplateSchema } from './schema';
import { DataLoadErrorMessage, LoadingMessage, LocalisedLabel, PageTitle } from '../../lib';
import { useFileName, useHistoricalSoftSensors } from '../../utils';
import useNow from '../../useNow';
import useStartDate from '../../FilterControls/useStartDate';
import useEndDate from '../../FilterControls/useEndDate';
import FilterControls from '../../FilterControls/FilterControls';
import Table from './Table/Table';
import ChartContainer from './ChartContainer/ChartContainer';

const HistoricalPerformanceImprovementPage = ({ label, options }: TemplateSchema) => {
  const now = useNow();
  const startDate = useStartDate(subHours(now, 24));
  const endDate = useEndDate(addHours(now, 6));

  const featureNames = [options.featureName];

  const releaseDate = options.releaseDate ? new Date(options.releaseDate) : startDate;
  const queryStartDate = startDate.getTime() < releaseDate.getTime() ? releaseDate : startDate;
  const { data, isLoading, isError } = useHistoricalSoftSensors(featureNames, queryStartDate, endDate);

  const fileName = useFileName(label, startDate, endDate);

  return (
    <>
      <PageTitle>
        <LocalisedLabel>{label}</LocalisedLabel>
      </PageTitle>
      <FilterControls isLoading={isLoading} showFutureWindow />

      {isLoading && <LoadingMessage />}
      {isError && <DataLoadErrorMessage />}

      {!isLoading && !isError && startDate && endDate && (
        <>
          <ChartContainer data={data} startDate={startDate} endDate={endDate} options={options} />
          <Table options={options} data={data} fileName={fileName} />
        </>
      )}
    </>
  );
};
export default HistoricalPerformanceImprovementPage;
