import opentelemetry from '@opentelemetry/api';
import { addHours, isAfter } from 'date-fns';
import { useEffect } from 'react';
import useNow from '../../../useNow';
import { isRealUser, useActiveRecommendation, useUserEmail } from '../utils';

const useHealthcheck = () => {
  const userEmail = useUserEmail();
  const trackUser = isRealUser(userEmail);

  const { data: recommendation, isLoading: isLoadingRecommendation } = useActiveRecommendation();
  const now = useNow();

  return useEffect(() => {
    const checkForProblems = () => {
      const getSpanName = () => {
        // 1 Recommendation data is loading
        if (isLoadingRecommendation) {
          return 'recommendationsHiddenRecommendationLoading';
        }
        // 2. no recommendation
        if (!recommendation) {
          return 'recommendationsHiddenNoRecommendation';
        }
        // 3. no recent recommendation available
        const recommendationCreatedAt = recommendation?.timestamp ?? false;
        if (recommendationCreatedAt && isAfter(now, addHours(recommendationCreatedAt, 5))) {
          return 'recommendationsHiddenNoRecentRecommendation';
        }
        // 4. recommendation has an error
        if (recommendation.error) {
          if (['INFERENCE_ERROR', 'BAD_DATA'].includes(recommendation.error)) {
            return 'recommendationsHiddenInternalError';
          }
          if (['NANS_IN_INPUT', 'NOT_ENOUGH_DATA'].includes(recommendation.error)) {
            return 'recommendationsHiddenNotEnoughData';
          }
          if (['PREDS_OUT_OF_BOUNDS', 'INFEASIBLE'].includes(recommendation.error)) {
            return 'recommendationsHiddenOutOfTargetBounds';
          }
          if (recommendation.error === 'EO_OFF') {
            return 'recommendationsHiddenEoOffline';
          }
          return 'recommendationsHiddenError';
        }

        return false;
      };
      const spanName = getSpanName();
      if (spanName) {
        opentelemetry.trace.getTracer('ezer').startSpan(spanName).end();
      }
    };

    const ONE_SECOND = 1000;
    let interval = 0;
    if (trackUser) {
      checkForProblems();
      interval = window.setInterval(() => {
        checkForProblems();
      }, ONE_SECOND);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isLoadingRecommendation, recommendation, now, trackUser]);
};

export default useHealthcheck;
