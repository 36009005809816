import { addMinutes } from 'date-fns';
import type { PlantData } from './useHistoricalPlantData';
import type { SoftSensor } from './useHistoricalSoftSensors';
import type { FeatureName, Recommendation } from '../types';
import type QualityChartData from '../types/QualityChartData';
import useNewKpiFeatureConfig from './useNewKpiFeatureConfig';
import { KPIFeatureConfig } from '../Config/sharedSchemas';
import round from './round';

const isInBounds = (value: number, upperBound: number, lowerBound: number) =>
  value >= lowerBound && value <= upperBound;

const useInitialTargetValue = (oldTargetData: Recommendation[], kpiFeatureConfig: KPIFeatureConfig | undefined) => {
  if (oldTargetData.length > 0) {
    return oldTargetData[oldTargetData.length - 1].value;
  }
  if (kpiFeatureConfig?.defaultTargetValue) {
    return kpiFeatureConfig.defaultTargetValue;
  }
  return 0;
};

const useCombinedQualityChartData = (
  modelledData: SoftSensor[],
  measuredData: PlantData,
  targetData: Recommendation[],
  oldTargetData: Recommendation[],
  modelledFeatureName: FeatureName,
  measuredFeatureName: FeatureName,
  endDate: Date,
  roundValues = false
): QualityChartData => {
  const kpiFeatureConfig = useNewKpiFeatureConfig(modelledFeatureName);
  const { items } = measuredData;
  const kpiUpperBound = kpiFeatureConfig?.upperBound ?? 0;
  const kpiLowerBound = kpiFeatureConfig?.lowerBound ?? 0;

  const defaultTargetValue = useInitialTargetValue(oldTargetData, kpiFeatureConfig);

  const data = [...items].reverse().reduce((acc: QualityChartData, item) => {
    const date = new Date(item.timestamp).getTime();
    const measured = item.data[measuredFeatureName]?.value;
    const predicted = modelledData.find((datum) => datum.timestamp.getTime() === date)?.value;
    const targetValue = targetData.find((datum) => datum.timestamp.getTime() === date)?.value;
    const lastUpperBoundValue = acc[acc.length - 1]?.upperBound ?? defaultTargetValue + kpiUpperBound;
    const upperBound = targetValue === undefined ? lastUpperBoundValue : targetValue + kpiUpperBound;
    const lastLowerBoundValue = acc[acc.length - 1]?.lowerBound ?? defaultTargetValue + kpiLowerBound;
    const lowerBound = targetValue === undefined ? lastLowerBoundValue : targetValue + kpiLowerBound;
    const predictedInBounds = predicted === undefined ? true : isInBounds(predicted, upperBound, lowerBound);

    acc.push({
      date,
      predicted: predicted !== undefined && roundValues ? round(predicted, 0) : predicted,
      measured: measured !== undefined && roundValues ? round(measured, 0) : measured,
      upperBound,
      lowerBound,
      predictedInBounds
    });
    return acc;
  }, []);

  const lastDataItem = data[data.length - 1];

  // extend upper and lower band to the end of the chart

  if (lastDataItem) {
    for (let counter = lastDataItem.date; counter < endDate.getTime(); counter = addMinutes(counter, 15).getTime()) {
      data.push({
        date: counter,
        upperBound: lastDataItem.upperBound,
        lowerBound: lastDataItem.lowerBound,
        predictedInBounds: true
      });
    }
  }

  return data;
};
export default useCombinedQualityChartData;
