import { SoftSensor } from '../../../utils/useHistoricalSoftSensors';
import Chart from './Chart';
import useChartData from './useChartData';
import { Options } from '../schema';

type Props = {
  data: SoftSensor[];
  startDate: Date;
  endDate: Date;
  options: Options;
};
const ChartContainer = ({ data, startDate, endDate, options }: Props) => {
  const chartData = useChartData(data, options.featureName, options.lowerBound);

  return <Chart data={chartData} startDate={startDate} endDate={endDate} options={options} />;
};

export default ChartContainer;
