import { subDays } from 'date-fns';
import useConfig from '../../Config/useConfig';
import useLatestPlantDataQuery from '../../serviceQueries/useLatestPlantDataQuery';
import useNow from '../../useNow';

type Response = {
  isLoading: boolean;
  data: boolean;
};
const useClosedLoopControlStatus = (): Response => {
  const { isLoading: isLoadingConfig, data: config } = useConfig();
  const featureName = config?.app?.clcStatusFeature;
  const now = useNow();
  const startDate = subDays(now, 7);
  const featureNames = featureName === undefined ? [] : [featureName];
  const { isLoading: isLoadingPlantData, data: plantData } = useLatestPlantDataQuery(featureNames, startDate, now);
  const isLoading = isLoadingConfig || isLoadingPlantData;
  const value = featureName === undefined ? 0 : plantData?.item[featureName]?.value ?? 0;
  return {
    data: value > 0,
    isLoading
  };
};

export default useClosedLoopControlStatus;
