import Plot from 'react-plotly.js';
// @ts-ignore
import * as localeDictionary from 'plotly.js/lib/locales/pt-br';
import { useIntl } from 'react-intl';
import { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { EzerTheme, useEzerTheme } from '../../../EzerThemeProvider';
import { SoftSensor } from '../../../utils/useHistoricalSoftSensors';
import { PlantData } from '../../../utils/useHistoricalPlantData';
import {
  useCombinedQualityChartData,
  useLocalisedLabel,
  useQualityChartYTicks,
  useReferenceAreaData,
  useTicksByDate,
  useUnit
} from '../../../utils';
import { FeatureName, Label, Recommendation } from '../../../types';

type Props = {
  startDate: Date;
  endDate: Date;
  modelledData: SoftSensor[];
  measuredData: PlantData;
  targetData: Recommendation[];
  oldTargetData: Recommendation[];
  modelled: FeatureName;
  measured: FeatureName;
  xAxisLabel: Label;
  yAxisLabel: Label;
  rangeStart?: number;
  rangeEnd?: number;
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    marginTop: spacing(2),
    width: '100%',
    overflow: 'hidden'
  }
}));
const PlotlyChartContainer = ({
  startDate,
  endDate,
  modelledData,
  measuredData,
  targetData,
  oldTargetData,
  modelled,
  measured,
  xAxisLabel,
  yAxisLabel,
  rangeStart,
  rangeEnd
}: Props) => {
  const styles = useStyles();
  const { spacing, palette, fontWeight } = useEzerTheme();

  const [width, setWidth] = useState(0);

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const getWidth = () => containerRef.current?.offsetWidth ?? 0;

    const handleResize = () => {
      setWidth(getWidth());
    };
    if (containerRef.current) {
      setWidth(getWidth());
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [containerRef]);

  const chartData = useCombinedQualityChartData(
    modelledData,
    measuredData,
    targetData,
    oldTargetData,
    modelled,
    measured,
    endDate,
    false
  );

  const filteredModelledData = chartData.filter((datum) => datum.predicted !== undefined);
  const filteredMeasuredData = chartData.filter((datum) => datum.measured !== undefined);
  const filteredUpperBoundData = chartData.filter((datum) => datum.upperBound !== undefined);
  const filteredLowerBoundData = chartData.filter((datum) => datum.lowerBound !== undefined);
  const referenceAreaData = useReferenceAreaData(chartData);
  const xTicks = useTicksByDate(startDate, endDate);
  const yTicks = useQualityChartYTicks(
    chartData,
    rangeStart === null ? undefined : rangeStart,
    rangeEnd === null ? undefined : rangeEnd
  );

  const { formatMessage, locale } = useIntl();
  const modelledLabel = formatMessage({ id: 'chart.quality.predicted', defaultMessage: 'Modelled' });
  const measuredLabel = formatMessage({ id: 'chart.quality.measured', defaultMessage: 'Measured' });
  const xAxisLabelText = useLocalisedLabel(xAxisLabel);
  const yAxisLabelText = useLocalisedLabel(yAxisLabel);
  const { data: unit } = useUnit(measured);

  return (
    <div className={styles.root} ref={containerRef}>
      <Plot
        data={[
          {
            x: filteredUpperBoundData.map((datum) => new Date(datum.date)),
            y: filteredUpperBoundData.map((datum) => datum.upperBound ?? 0),
            type: 'scatter',
            mode: 'lines+markers',
            marker: { color: 'transparent' },
            width: 1,
            line: {
              width: 1,
              color: palette.white,
              dash: 'dash',
              shape: 'spline'
            },
            showlegend: false,
            hoverinfo: 'none'
          },
          {
            x: filteredLowerBoundData.map((datum) => new Date(datum.date)),
            y: filteredLowerBoundData.map((datum) => datum.lowerBound ?? 0),
            type: 'scatter',
            mode: 'lines+markers',
            marker: { color: 'transparent' },
            width: 1,
            line: {
              width: 1,
              color: palette.white,
              dash: 'dash',
              shape: 'spline'
            },
            showlegend: false,
            hoverinfo: 'none'
          },
          {
            x: filteredMeasuredData.map((datum) => new Date(datum.date)),
            y: filteredMeasuredData.map((datum) => datum.measured ?? 0),
            type: 'scatter',
            mode: 'lines+markers',
            marker: { color: palette.white, size: 8 },
            line: {
              color: 'transparent'
            },
            name: `${measuredLabel} (${unit})`
          },
          {
            x: filteredModelledData.map((datum) => new Date(datum.date)),
            y: filteredModelledData.map((datum) => datum.predicted ?? 0),
            type: 'scatter',
            mode: 'lines+markers',
            marker: { color: 'transparent' },
            width: 2,
            line: {
              color: palette.leaf,
              shape: 'spline'
            },
            name: `${modelledLabel} (${unit})`
          }
        ]}
        layout={{
          autosize: true,
          width,
          height: spacing(58),
          paper_bgcolor: palette.rich,
          plot_bgcolor: palette.carbon,
          font: {
            color: palette.white,
            family: "'Mada', 'arial', 'helvetica', 'sans-serif'",
            size: spacing(2),
            weight: fontWeight.medium
          },
          hovermode: 'x unified',
          hoverdistance: 1,
          hoverlabel: {
            bgcolor: palette.rich,
            bordercolor: palette.rich,
            font: { color: palette.white }
          },
          margin: { t: spacing(0), b: spacing(8), l: spacing(8), r: 0 },
          xaxis: {
            range: [startDate, endDate],
            gridcolor: palette.rich,
            title: { text: xAxisLabelText },
            linecolor: palette.white,
            tickmode: 'array',
            tickvals: xTicks.map((tick) => new Date(tick)),
            tickformat: '%Y-%m-%d',
            hoverformat: '%Y-%m-%d %H:%M',
            tickson: 'labels',
            tickcolor: palette.white,
            ticklen: 4,
            spikecolor: palette.white,
            spikethickness: 1,
            spikedash: '0px'
          },
          yaxis: {
            range: [rangeStart, rangeEnd],
            gridcolor: palette.rich,
            linecolor: palette.white,
            title: { text: `${yAxisLabelText} (${unit})` },
            tickmode: 'array',
            tickvals: yTicks,
            tickformat: '.1f',
            tickson: 'labels',
            tickcolor: palette.white,
            ticklen: 4
          },
          shapes: referenceAreaData.map((datum) => ({
            type: 'rect',
            x0: datum.start,
            x1: datum.end,
            y0: rangeStart,
            y1: rangeEnd,
            opacity: 0.25,
            fillcolor: palette.flame
          })),
          legend: {
            orientation: 'h',
            yanchor: 'bottom',
            y: 1.02,
            xanchor: 'center',
            x: 0.5
          }
        }}
        config={{
          toImageButtonOptions: {
            filename: 'chart'
          },
          displaylogo: false,
          modeBarButtonsToRemove: ['lasso2d', 'select2d', 'autoScale2d'],
          locales: { 'pt-br': localeDictionary },
          locale
        }}
      />
    </div>
  );
};

export default PlotlyChartContainer;
