import { Api } from '../settings';
import { FeatureName } from '../types';
import { getUtcDate, usePartition } from '../utils';
import useAuthorisedQuery from './useAuthorisedQuery';

const HALF_MINUTE = 30 * 1000;

export const CACHE_KEY = ['latest-recommendations', 'limit-2'];

type Options = {
  useInferenceApi?: boolean;
};

const useLatestRecommendationsQuery = (
  featureNames: FeatureName[],
  startDate: Date,
  endDate: Date,
  options?: Options
) => {
  const useInferenceApi = !!options?.useInferenceApi;
  const api = useInferenceApi ? Api.inference : Api.serve;
  const features = featureNames.join(',');
  const startDateValue = getUtcDate(startDate);
  const endDateValue = getUtcDate(endDate);
  const partition = usePartition();

  return useAuthorisedQuery(
    [...CACHE_KEY, `api-${api}`],
    `recommendation/?features=${features}&start_datetime=${startDateValue}&end_datetime=${endDateValue}&limit=2&partition=${partition}`,
    {
      refetchInterval: HALF_MINUTE
    },
    api
  );
};
export default useLatestRecommendationsQuery;
