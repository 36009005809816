import { Static, Type } from '@sinclair/typebox';
import { LabelSchema } from '../../Config/sharedSchemas';
import { selectableEnum } from '../../Config/utils';
import { FeatureName } from '../../types';
import getTemplateSchema from '../getTemplateSchema';

const options = Type.Object(
  {
    title: LabelSchema({ title: 'Title', description: 'The title of the chart' }),
    featureName: selectableEnum(FeatureName, {
      title: 'Feature Name',
      description: 'The name of the performance feature'
    }),
    xAxisLabel: LabelSchema({ title: 'X Axis Label', description: 'Label for the X axis' }),
    yAxisLabel: LabelSchema({ title: 'Y Axis Label', description: 'Label for the Y axis' }),
    numberOfTicks: Type.Optional(
      Type.Number({ title: 'Number of Ticks', description: 'Number of ticks to display on the Y axis' })
    ),
    rangeStart: Type.Optional(
      Type.Number({
        title: 'Range Start',
        description: 'The start of the range on the Y axis'
      })
    ),
    rangeEnd: Type.Optional(Type.Number({ title: 'Range End', description: 'The end of the range on the Y axis' })),
    releaseDate: Type.Optional(
      Type.String({
        title: 'Release Date',
        description: 'Only show values after this date'
      })
    ),
    lowerBound: Type.Optional(
      Type.Number({
        title: 'Lower Bound',
        description: 'Highlight any values below this amount'
      })
    )
  },
  { title: 'Options', description: 'Options for the Historical Performance Improvement template' }
);

export type Options = Static<typeof options>;

export const templateSlug = 'HistoricalPerformanceImprovementPage';
export const templateSchema = Type.Object(getTemplateSchema({ templateSlug, options }), {
  title: 'Historical Performance Improvement Page',
  description: 'Historical Performance Improvement page for new config etc etc'
});
export type TemplateSchema = Static<typeof templateSchema>;
export const templateUiSchema = {
  featureFlag: {
    'ui:options': {
      // placeholder to show how to set a field option
      disabled: false
    }
  }
};
