import { Api } from '../settings';
import { getUtcDate } from '../utils';
import useAuthorisedQuery from './useAuthorisedQuery';

const ONE_MINUTE = 60000;
const useDataStatusQuery = (startDate: Date, endDate: Date) => {
  const path = 'data-status';
  const startDateValue = getUtcDate(startDate);
  const endDateValue = getUtcDate(endDate);
  return useAuthorisedQuery(
    [path],
    `${path}/?start_datetime=${startDateValue}&end_datetime=${endDateValue}`,
    {
      refetchInterval: ONE_MINUTE
    },
    Api.serve
  );
};

export default useDataStatusQuery;
