import { HistoricalQualityChart } from '../../lib';
import { FeatureName, Label, Recommendation } from '../../types';
import { useCombinedQualityChartData, useUnit } from '../../utils';
import { PlantData } from '../../utils/useHistoricalPlantData';
import { SoftSensor } from '../../utils/useHistoricalSoftSensors';

type Props = {
  modelledData: SoftSensor[];
  measuredData: PlantData;
  targetData: Recommendation[];
  oldTargetData: Recommendation[];
  modelled: FeatureName;
  measured: FeatureName;
  startDate: Date;
  endDate: Date;
  xAxisLabel: Label;
  yAxisLabel: Label;
  rangeStart?: number;
  rangeEnd?: number;
  roundValues?: boolean;
};
const ChartContainer = ({
  modelledData,
  measuredData,
  targetData,
  oldTargetData,
  modelled,
  measured,
  startDate,
  endDate,
  xAxisLabel,
  yAxisLabel,
  rangeStart,
  rangeEnd,
  roundValues
}: Props) => {
  const chartData = useCombinedQualityChartData(
    modelledData,
    measuredData,
    targetData,
    oldTargetData,
    modelled,
    measured,
    endDate,
    roundValues
  );
  const { data: unit } = useUnit(measured);

  return (
    <HistoricalQualityChart
      data={chartData}
      startDate={startDate}
      endDate={endDate}
      xAxisLabel={xAxisLabel}
      yAxisLabel={yAxisLabel}
      unit={unit}
      rangeStart={rangeStart}
      rangeEnd={rangeEnd}
    />
  );
};

export default ChartContainer;
