import PlantStatusTimeline from './PlantStatusTimeline/PlantStatusTimeline';
import Section from '../lib/Section';
import DataIngestionTimeline from './DataIngestionTimeline/DataIngestionTimeline';
import AlarmPanel from './AlarmPanel/AlarmPanel';
import useAlarmGroups from './AlarmPanel/useAlarmGroups';

const PlantSection = () => {
  const { data: alarmGroups = [] } = useAlarmGroups();
  return (
    <Section>
      <PlantStatusTimeline />
      <DataIngestionTimeline />
      {alarmGroups.length > 0 && <AlarmPanel />}
    </Section>
  );
};
export default PlantSection;
