import Plot from 'react-plotly.js';
import { subDays } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useIntl } from 'react-intl';
// @ts-ignore
import * as localeDictionary from 'plotly.js/lib/locales/pt-br';
import useKpiByHourData, { ChartData } from './useKpiByHourData';
import { KpiChartConfig } from '../../schema';
import { useEzerTheme } from '../../../../EzerThemeProvider';
import { useSevenTicks } from '../../utils';
import { ChartTitle } from '../../../../lib';
import useToday from '../../../../useToday';

type Props = {
  data: ChartData;
  unit: string;
  config: KpiChartConfig;
  startDate: Date;
  endDate: Date;
};

const useStyles = createUseStyles(() => ({
  root: {
    width: '100%',
    overflow: 'hidden'
  }
}));

const Heatmap = ({ data, unit, config, startDate, endDate }: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const styles = useStyles();
  const { spacing, palette, fontWeight } = useEzerTheme();
  const { locale } = useIntl();

  const [width, setWidth] = useState(0);
  const xTicks = useSevenTicks(startDate, endDate);

  useEffect(() => {
    const getWidth = () => containerRef.current?.offsetWidth ?? 0;

    const handleResize = () => {
      setWidth(getWidth());
    };
    if (containerRef.current) {
      setWidth(getWidth());
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [containerRef]);

  const { periods } = data;

  const { data: oneDayAgoData } = useKpiByHourData(config.feature.name, subDays(startDate, 1), subDays(endDate, 1));
  const { data: twoDaysAgoData } = useKpiByHourData(config.feature.name, subDays(startDate, 2), subDays(endDate, 1));
  const { data: threeDaysAgoData } = useKpiByHourData(config.feature.name, subDays(startDate, 3), subDays(endDate, 1));
  const { data: fourDaysAgoData } = useKpiByHourData(config.feature.name, subDays(startDate, 4), subDays(endDate, 1));
  const { data: fiveDaysAgoData } = useKpiByHourData(config.feature.name, subDays(startDate, 5), subDays(endDate, 1));
  const { data: sixDaysAgoData } = useKpiByHourData(config.feature.name, subDays(startDate, 6), subDays(endDate, 1));
  const today = useToday();
  const dates = [
    today,
    subDays(today, 1),
    subDays(today, 2),
    subDays(today, 3),
    subDays(today, 4),
    subDays(today, 5),
    subDays(today, 6)
  ];

  return (
    <>
      <ChartTitle>SHC for last 7 days</ChartTitle>

      <div className={styles.root} ref={containerRef}>
        <Plot
          data={[
            {
              z: [
                periods.map((period) => period.averageValue ?? 0),
                oneDayAgoData ? oneDayAgoData.periods.map((period) => period.averageValue ?? 0) : [],
                twoDaysAgoData ? twoDaysAgoData.periods.map((period) => period.averageValue ?? 0) : [],
                threeDaysAgoData ? threeDaysAgoData.periods.map((period) => period.averageValue ?? 0) : [],
                fourDaysAgoData ? fourDaysAgoData.periods.map((period) => period.averageValue ?? 0) : [],
                fiveDaysAgoData ? fiveDaysAgoData.periods.map((period) => period.averageValue ?? 0) : [],
                sixDaysAgoData ? sixDaysAgoData.periods.map((period) => period.averageValue ?? 0) : []
              ],
              x: periods.map((period) => new Date(period.hour)),
              y: dates,
              colorscale: [
                [0, palette.carbon],
                [1, palette.leaf]
              ],
              colorbar: {
                xpad: spacing(0.5),
                ypad: 0,
                thickness: spacing(2)
              },
              type: 'heatmap',
              name: 'SHC',
              hovertemplate: `%{z} ${unit}`
            }
          ]}
          layout={{
            // used to make chart responsive
            autosize: true,
            width,
            // CarbonRe branding
            paper_bgcolor: palette.rich,
            plot_bgcolor: palette.carbon,
            font: {
              color: palette.white,
              family: "'Mada', 'arial', 'helvetica', 'sans-serif'",
              size: spacing(2),
              weight: fontWeight.medium
            },
            // tooltip config
            hovermode: 'x unified',
            hoverdistance: 1,
            hoverlabel: {
              bgcolor: palette.rich,
              bordercolor: palette.rich,
              font: { color: palette.white }
            },
            // height and padding of graph
            height: spacing(37),
            margin: { t: spacing(3), b: spacing(4), l: spacing(6.5), r: spacing(1) },
            // y axis
            yaxis: {
              gridcolor: palette.rich,
              linecolor: palette.white,
              tickmode: 'array',
              tickson: 'labels',
              tickcolor: palette.white,
              tickformat: '%m-%d',
              hoverformat: '%Y-%m-%d',
              tickvals: dates
            },
            xaxis: {
              range: [startDate, endDate],
              gridcolor: palette.rich,
              linecolor: palette.white,
              tickmode: 'array',
              tickvals: xTicks.map((tick) => new Date(tick)),
              tickformat: '%H:%M',
              hoverformat: '%Y-%m-%d %H:%M',
              tickson: 'labels',
              tickcolor: palette.white,
              spikecolor: palette.white,
              spikethickness: 1,
              spikedash: '0px'
            }
          }}
          config={{
            // customise the toolbar
            toImageButtonOptions: {
              filename: 'chart'
            },
            displaylogo: false,
            modeBarButtonsToRemove: ['lasso2d', 'select2d', 'autoScale2d'],
            locales: { 'pt-br': localeDictionary },
            locale
          }}
        />
      </div>
    </>
  );
};

export default Heatmap;
