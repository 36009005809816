import useHistoricalPlantDataQuery from '../serviceQueries/useHistoricalPlantDataQuery';
import { FeatureName, MetricStatus } from '../types';

interface PlantDataRecord {
  value: number;
  status: MetricStatus;
  type: string;
  error?: string;
}

export type PlantDataItemData = Partial<Record<keyof typeof FeatureName, PlantDataRecord>>;

type PlantDataItem = {
  id: number;
  timestamp: string;
  data: PlantDataItemData;
};

export type PlantData = {
  items: PlantDataItem[];
};

type Response = {
  data: PlantData;
  isLoading: boolean;
  isError: boolean;
};

const useHistoricalPlantData = (
  featureNames: FeatureName[],
  startDate: Date,
  endDate: Date,
  sampleRate = 15,
  refetch = false
): Response => {
  const {
    data = {},
    isLoading,
    isError
  } = useHistoricalPlantDataQuery(featureNames, startDate, endDate, sampleRate, {
    refetch,
    disable: featureNames.length === 0
  });
  return {
    data,
    isLoading,
    isError
  };
};

export default useHistoricalPlantData;
