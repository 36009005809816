import { SoftSensor } from '../../../utils/useHistoricalSoftSensors';
import useColumnData from './useColumnData';
import useTableData from './useTableData';
import { DataTable } from '../../../lib';
import { Options } from '../schema';

type Props = {
  options: Options;
  data: SoftSensor[];
  fileName: string;
};

const Table = ({ options, data, fileName }: Props) => {
  const columnData = useColumnData(options);
  const tableData = useTableData(data);

  return <DataTable data={tableData} columns={columnData} fileName={fileName} />;
};

export default Table;
