const useRangedTicks = (tickCount: number, rangeStart?: number, rangeEnd?: number): number[] => {
  if (rangeStart === undefined || rangeEnd === undefined) {
    return [];
  }
  const ticks = [];
  const period = (rangeEnd - rangeStart) / (tickCount - 1);
  for (let counter = 0; counter < tickCount; counter++) {
    ticks.push(rangeStart + period * counter);
  }
  return ticks;
};

export default useRangedTicks;
