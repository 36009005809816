import React from 'react';
import { Line, LineChart, ReferenceArea, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { createUseStyles } from 'react-jss';
import { ChartData } from './useChartData';
import { EzerTheme, useEzerTheme } from '../../../EzerThemeProvider';
import { useTicksByDate, getLocalDate, useTimezone, useLocalisedLabel } from '../../../utils';
import useYTicks from './useYTicks';
import { LabelledContainer } from '../../../lib';
import CartesianGrid from '../../../lib/CartesianGrid';
import TooltipContent from './TooltipContent';
import { Options } from '../schema';
import useReferenceAreaData from './useReferenceAreaData';

type Props = {
  data: ChartData;
  startDate: Date;
  endDate: Date;
  options: Options;
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    marginTop: spacing(2)
  },
  title: {
    textAlign: 'center',
    fontSize: spacing(4),
    marginTop: spacing(2)
  },
  referenceArea: {
    opacity: 0.5
  },
  container: {
    position: 'relative'
  }
}));

const Chart = ({ data, startDate, endDate, options }: Props) => {
  const styles = useStyles();
  const { palette, spacing } = useEzerTheme();
  const { xAxisLabel, yAxisLabel, rangeStart = 0, rangeEnd = 100 } = options;

  const xTicks = useTicksByDate(startDate, endDate);

  const showDecimalPoints = rangeStart ? rangeEnd <= 1000 : true;
  const timezone = useTimezone();
  const leftYTicks = useYTicks(rangeStart, rangeEnd);
  const leftDomain =
    leftYTicks && leftYTicks.length > 0 ? [leftYTicks[0], leftYTicks[leftYTicks.length - 1]] : undefined;
  const unit = '%';
  const referenceAreaData = useReferenceAreaData(data);
  const localisedTitle = useLocalisedLabel(options.title);
  return (
    <>
      <h2 className={styles.title}>{localisedTitle}</h2>
      <LabelledContainer yAxisLabel={yAxisLabel} xAxisLabel={xAxisLabel} unit={unit}>
        <ResponsiveContainer width="100%" height={spacing(58)} className={styles.root}>
          <LineChart margin={{ top: spacing(1), right: 0, bottom: 0, left: 0 }}>
            <CartesianGrid />
            {referenceAreaData.map(({ start, end }) => (
              <ReferenceArea key={start} x1={start} x2={end} fill={palette.flame} className={styles.referenceArea} />
            ))}
            {options.lowerBound !== undefined && (
              <ReferenceLine y={options.lowerBound} stroke={palette.white} strokeWidth={1} />
            )}
            <Line
              type="linear"
              data={data}
              dataKey="value"
              stroke={palette.leaf}
              strokeWidth={2}
              isAnimationActive={false}
              dot={false}
              connectNulls
            />
            <XAxis
              dataKey="date"
              type="number"
              domain={[startDate.getTime(), endDate.getTime()]}
              ticks={xTicks}
              tickFormatter={(tick: string) => getLocalDate(new Date(tick), timezone)}
              allowDataOverflow
              height={spacing(4)}
            />
            <YAxis
              width={spacing(6)}
              dataKey={options.featureName}
              ticks={leftYTicks}
              domain={leftDomain}
              allowDataOverflow
              tickFormatter={(tick: number) => tick.toFixed(showDecimalPoints ? 1 : 0)}
            />
            {data && <Tooltip content={<TooltipContent unit={unit} featureLabel={yAxisLabel} />} />}
          </LineChart>
        </ResponsiveContainer>
      </LabelledContainer>
    </>
  );
};

export default Chart;
