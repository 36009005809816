import { formatInTimeZone } from 'date-fns-tz';
import { createUseStyles } from 'react-jss';
import { EzerTheme } from '../EzerThemeProvider';
import { useCurrentTime, useTimezone } from '../utils';

const formatTime = (date: Date, timezone: string) => formatInTimeZone(date, timezone, 'yyyy-MM-dd HH:mm:ss zzz');

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    display: 'inline-block',
    marginLeft: spacing(2),
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
    lineHeight: `${spacing(3)}px`
  }
}));

const Clock = () => {
  const styles = useStyles();
  const timezone = useTimezone();
  const time = useCurrentTime();

  return <span className={styles.root}>{formatTime(time, timezone)}</span>;
};

export default Clock;
