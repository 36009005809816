import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const KEYS = ['now', 'features', 'customer', 'timezone', 'language', 'configAlias', 'partition'];

const usePath = (to: string) => {
  const { search } = useLocation();
  return useMemo(() => {
    const params: string[] = [];
    const query = new URLSearchParams(search);

    KEYS.forEach((feature) => {
      const featureValue = query?.get(feature);
      if (featureValue) {
        params.push(`${feature}=${featureValue}`);
      }
    });

    return params.length === 0 ? to : `${to}?${params.join('&')}`;
  }, [to, search]);
};
export default usePath;
