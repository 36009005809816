import { Api } from '../settings';
import { FeatureName } from '../types';
import getCacheKeyDate from '../utils/getCacheKeyDate';
import getUtcDate from '../utils/getUtcDate';
import useAuthorisedQuery from './useAuthorisedQuery';
import usePartition from '../utils/usePartition';

const ONE_MINUTE = 60000;

const buildQueryKey = (startDate: Date, endDate: Date, featureNames: FeatureName[], refetchMode = false) => {
  const result = ['historical-soft-sensors', ...featureNames];
  if (!refetchMode) {
    result.push(getCacheKeyDate(startDate));
    result.push(getCacheKeyDate(endDate));
  }
  return result;
};

type Options = {
  refetch?: boolean;
  useInferenceApi?: boolean;
};

const useHistoricalSoftSensorsQuery = (
  featureNames: FeatureName[],
  startDate: Date,
  endDate: Date,
  options?: Options
) => {
  const startDateValue = getUtcDate(startDate);
  const endDateValue = getUtcDate(endDate);
  const refetchMode = !!options?.refetch;
  const partition = usePartition();
  const features = featureNames.join(',');

  const path = `soft-sensor/?features=${features}&partition=${partition}&start_datetime=${startDateValue}&end_datetime=${endDateValue}`;

  return useAuthorisedQuery(
    buildQueryKey(startDate, endDate, featureNames, refetchMode),
    path,
    {
      refetchInterval: refetchMode ? ONE_MINUTE : false
    },
    Api.inference
  );
};
export default useHistoricalSoftSensorsQuery;
