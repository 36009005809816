import { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useIntl } from 'react-intl';
import Plot from 'react-plotly.js';
// @ts-ignore
import * as localeDictionary from 'plotly.js/lib/locales/pt-br';
import { ChartData } from './useKpiByHourData';
import { KpiChartConfig } from '../../schema';
import { useEzerTheme } from '../../../../EzerThemeProvider';
import { useSevenTicks } from '../../utils';

type Props = {
  data: ChartData;
  unit: string;
  config: KpiChartConfig;
  startDate: Date;
  endDate: Date;
  displayRightAxis?: boolean;
};

const useStyles = createUseStyles(() => ({
  root: {
    width: '100%',
    overflow: 'hidden'
  }
}));

const getMaxValue = (data: ChartData, unit: string) => {
  const { periods = [], baseline = 0 } = data;
  const highestValue = periods.reduce(
    (current, period) => (period.averageValue > current ? period.averageValue : current),
    baseline
  );
  return unit === 'kcal/kg' ? Math.ceil(highestValue / 200) * 200 : Math.ceil(highestValue);
};

const PlotlyChart = ({ data, unit, config, startDate, endDate, displayRightAxis = false }: Props) => {
  const styles = useStyles();
  const { spacing, palette, fontWeight } = useEzerTheme();

  const [width, setWidth] = useState(0);

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const getWidth = () => containerRef.current?.offsetWidth ?? 0;

    const handleResize = () => {
      setWidth(getWidth());
    };
    if (containerRef.current) {
      setWidth(getWidth());
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [containerRef]);
  const leftMargin = displayRightAxis ? spacing(6.5) : 0;
  const rightMargin = displayRightAxis ? spacing(9) : 0;
  const xTicks = useSevenTicks(startDate, endDate);
  const { rangeStart, rangeEnd } = config;
  const bottomValue = rangeStart === undefined ? 0 : rangeStart;
  const topValue = rangeEnd === undefined ? getMaxValue(data, unit) : rangeEnd;
  const { locale } = useIntl();
  const { periods = [], baseline = 0 } = data;

  return (
    <div className={styles.root} ref={containerRef}>
      <Plot
        data={[
          {
            x: periods.map((period) => new Date(period.hour)),
            y: periods.map((period) => period.averageValue),
            type: 'bar',
            marker: {
              color: palette.leaf
            },
            name: 'SHC',
            hovertemplate: `%{y} ${unit}`
          },
          {
            x: [],
            y: [],
            yaxis: 'y2',
            type: 'scatter'
          }
        ]}
        layout={{
          autosize: true,
          width,
          bargap: 0.1,
          height: spacing(37),
          paper_bgcolor: palette.rich,
          plot_bgcolor: palette.carbon,
          font: {
            color: palette.white,
            family: "'Mada', 'arial', 'helvetica', 'sans-serif'",
            size: spacing(2),
            weight: fontWeight.medium
          },
          hovermode: 'x unified',
          hoverdistance: 1,
          hoverlabel: {
            bgcolor: palette.rich,
            bordercolor: palette.rich,
            font: { color: palette.white }
          },
          margin: { t: spacing(3), b: spacing(4), l: leftMargin, r: rightMargin },
          xaxis: {
            range: [startDate, endDate],
            gridcolor: palette.rich,
            linecolor: palette.white,
            tickmode: 'array',
            tickvals: xTicks.map((tick) => new Date(tick)),
            tickformat: '%H:%M',
            hoverformat: '%Y-%m-%d %H:%M',
            tickson: 'labels',
            tickcolor: palette.white,
            spikecolor: palette.white,
            spikethickness: 1,
            spikedash: '0px'
          },
          yaxis: {
            range: [bottomValue, topValue],
            gridcolor: palette.rich,
            linecolor: palette.white,
            tickmode: 'array',
            tickson: 'labels',
            tickcolor: palette.white,
            tickvals: [0, 300, 600, 900, 1200]
          },
          yaxis2: {
            range: [bottomValue, topValue],
            gridcolor: palette.rich,
            linecolor: palette.white,
            tickmode: 'array',
            tickson: 'labels',
            tickcolor: palette.white,
            tickvals: [0, 300, 600, 900, 1200],
            overlaying: 'y',
            side: 'right'
          },
          shapes: [
            {
              type: 'line',
              x0: startDate,
              x1: endDate,
              y0: baseline,
              y1: baseline,
              line: {
                dash: 'dash',
                color: palette.white
              }
            }
          ]
        }}
        config={{
          toImageButtonOptions: {
            filename: 'chart'
          },
          displaylogo: false,
          modeBarButtonsToRemove: ['lasso2d', 'select2d', 'autoScale2d'],
          locales: { 'pt-br': localeDictionary },
          locale
        }}
      />
    </div>
  );
};

export default PlotlyChart;
