import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import useClosedLoopControlStatus from './useClosedLoopControlStatus';
import { EzerTheme } from '../../EzerThemeProvider';

const useStyles = createUseStyles(({ spacing, palette }: EzerTheme) => ({
  root: {
    display: 'inline-block',
    padding: [spacing(1), spacing(2), spacing(1), spacing(2)],
    lineHeight: `${spacing(3)}px`,
    backgroundColor: palette.lava,
    position: 'relative'
  },
  active: {
    backgroundColor: palette.leaf
  }
}));
const ClosedLoopStatusFlag = () => {
  const { data } = useClosedLoopControlStatus();
  const styles = useStyles();

  return (
    <span className={classNames(styles.root, { [styles.active]: data })}>
      <>Closed Loop Control </>
      {data && <>Active</>}
      {!data && <>Inactive</>}
    </span>
  );
};

export default ClosedLoopStatusFlag;
