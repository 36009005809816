import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

const usePartition = () => {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  return query?.get('partition') || 'main';
};

export default usePartition;
